<template>
  <router-link :to="{ name: 'public-article', params: { id: id } }">
    <article
      :style="{ 'background-image' : `url( ${ image } )` }"
      class="d-flex"
      :class="size"
    >
      <div class="overlay"></div>
      <vsvg-icon
        v-if="isVideo"
        data="@assetsIcon/play-icon.svg"
        scale="1"
        original
      />
      <div class="featured-wrapper">
        <span class="pill text-white">Featured</span>
        <h1 class="text-white">
          {{ title }}
        </h1>
        <div
          v-if="size == 'lg'"
          class="author-wrapper"
        >
          <span
            class="img-author"
            :style="{ 'background-image' : `url( ${ authorAvatarUrl } )` }"
          ></span>
          <span class="text-white">{{ author }}</span>
          <span class="date-creation text-white">{{ publishedAt }}</span>
        </div>
      </div>
    </article>
  </router-link>
</template>

<script>
export default {
  name: 'FeaturedArticleComponent',

  props: {
    id: {
      type: Number,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    isVideo: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    authorAvatarUrl: {
      type: String,
      required: true,
    },
    author: {
      type: String,
      required: true,
    },
    publishedAt: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'lg'
    }
  }
}
</script>

<style lang="scss" scoped>
article {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  &.lg {
    height: 466px;
    @media (max-width: 767px) {
      height: 300px;
    }

    .featured-wrapper {
      padding: 0 60px 20px 60px;
    }
  }
  &.sm {
    height: 233px;

    .featured-wrapper {
      padding: 0 20px 20px 20px;

      .pill {
        padding: 4px 16px;
        font-size: 14px;
        margin-bottom: 5px;
      }

      h1 {
        font-size: 18px;
        line-height: 30px;
      }
    }
  }

  .overlay {
    opacity: 0.70;
    background-image: linear-gradient(-180deg, rgba(8, 8, 8, 0) 6%, #000000 75%);
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
  }

  .featured-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: auto;
    z-index: 10;

    .pill {
      display: block;
      font-size: 18px;
      padding: 4px 25px;
      background-color: $white-color;
      color: $primary-grey-color;
      border-radius: 20px;
      margin-bottom: 15px;
    }

    h1 {
      margin: 0;
    }

    .author-wrapper {
      display: flex;
      align-items: center;
      position: relative;
      margin-top: 25px;

      span {
        font-size: 12px;
      }

      .date-creation {
        margin-left: 10px;
        padding-left: 30px;
        &:before {
          content: '|';
          position: absolute;
          right: 80px;
        }
      }
      .img-author {
        width: 38px;
        height: 38px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        margin-right: 10px;
        border-radius: 50%;
      }
    }
  }
}
</style>
