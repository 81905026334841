<!-- Home Component -->
<template>
  <div class="container-fluid home-page">
    <el-row
      v-loading="loading"
      :gutter="0"
      class="hidden-sm-and-down featured-articles"
    >
      <el-col
        v-if="ftArticle"
        :xl="16"
        :lg="16"
        :md="16"
        :sm="24"
        :xs="24"
        class="article-featured"
      >
        <featured-article
          :id="ftArticle.id"
          :image="ftArticle.mediaFile.is_image ? ftArticle.mediaFile.large_thumb : ftArticle.mediaFile.video_thumb"
          :is-video="!ftArticle.mediaFile.is_image"
          :title="ftArticle.title"
          :author-avatar-url="ftArticle.author_avatar_url"
          :author="ftArticle.author"
          :published-at="ftArticle.published_at"
        />
      </el-col>
      <el-col
        v-if="asArticles"
        :xl="8"
        :lg="8"
        :md="8"
        class="d-flex flex-column articles-aside"
      >
        <featured-article
          v-for="(article, index) in asArticles"
          :id="article.id"
          :key="index"
          :image="article.mediaFile.is_image ? article.mediaFile.large_thumb : article.mediaFile.video_thumb"
          :is-video="!article.mediaFile.is_image"
          :title="article.title"
          :author-avatar-url="article.author_avatar_url"
          :author="article.author"
          :published-at="article.published_at"
          size="sm"
        />
      </el-col>
    </el-row>
    <div
      v-loading="loading"
      class="home-slider"
    >
      <el-carousel
        :interval="5000"
        arrow="always"
      >
        <el-carousel-item
          v-for="(articleSlide, index) in asArticles"
          :key="index"
        >
          <router-link :to="{ name: 'public-article', params: { id: articleSlide.id } }">
            <div
              class="article-slider-items"
              :style="{ 'background-image' : `url( ${ articleSlide.mediaFile.is_image? articleSlide.mediaFile.large_thumb: articleSlide.mediaFile.video_thumb } )` }"
            >
              <div class="article-slider">
                <span>ARTICLE</span>
                <h2>{{ articleSlide.title }}</h2>
              </div>
            </div>
          </router-link>
        </el-carousel-item>
      </el-carousel>
    </div>
    <section class="join-hub d-flex">
      <div class="container">
        <span>Download the app to join the {{ brand.name }}</span>
        <div class="download-app">
          <a
            target="_blank"
            :href="brand.appStoreLink"
          >
            <vsvg-icon
              data="@assetsIcon/app-store-icon.svg"
              scale="1"
              original
            />
          </a>
          <a
            target="_blank"
            :href="brand.playStoreLink"
          >
            <vsvg-icon
              data="@assetsIcon/play-market-icon.svg"
              scale="1"
              original
            />
          </a>
        </div>
      </div>
    </section>
    <section
      v-loading="loading"
      class="articles"
    >
      <div class="container">
        <el-row class="el-row-articles">
          <el-col
            :xl="12"
            :lg="12"
            :md="12"
            :sm="16"
            :xs="24"
          >
            <span>Top stories and events this month</span>
          </el-col>
          <el-col
            :xl="12"
            :lg="12"
            :md="12"
            :sm="8"
            :xs="24"
          >
            <router-link
              v-slot="{ navigate }"
              :to="{ name: 'public-why' }"
              custom
            >
              <button
                class="fgh-button mini fgh-button--primary-outline-grey pull-right btn-view-more"
                @click="navigate"
              >
                View more
              </button>
            </router-link>
          </el-col>
        </el-row>
        <el-row :gutter="30">
          <el-col
            v-for="(newsItem, index) in news"
            :key="index"
            :xl="6"
            :lg="8"
            :md="12"
            :sm="12"
            :xs="24"
            class="el-col-newsitem"
          >
            <router-link
              v-if="newsItem.feed_key === 'Article'"
              :to="{ name: 'public-article', params: { id: newsItem.id } }"
            >
              <article-card
                :media="newsItem.mediaFile.is_image? newsItem.mediaFile.large_thumb: newsItem.mediaFile.video_thumb"
                :is-image="newsItem.mediaFile.is_image"
                :title="newsItem.title"
                :author-name="newsItem.author"
                :author-avatar="newsItem.author_avatar_url"
                :date="newsItem.published_at"
                :featured="newsItem.is_featured"
              />
            </router-link>
            <router-link
              v-if="newsItem.feed_key === 'Event'"
              :to="{ name: 'public-event', params: { id: newsItem.id } }"
            >
              <event-card
                :title="newsItem.title"
                :featured="newsItem.is_featured"
                :venue="newsItem.address_line_1"
                :start-date="newsItem.start_date"
                :end-date="newsItem.end_date"
                :start-time="newsItem.start_time"
                :end-time="newsItem.end_time"
                :same-day="newsItem.same_day"
              />
            </router-link>
          </el-col>
        </el-row>
      </div>
    </section>
  </div>
</template>

<script>
import feedApi from '@/modules/feed/services/feed.api'
import ArticleCard from '@/modules/feed/components/article-card'
import EventCard from '@/modules/feed/components/event-card'
import FeaturedArticle from '../components/featured-article'

export default {
  name: 'PublicHome',

  components: {
    'featured-article': FeaturedArticle,
    'article-card' : ArticleCard,
    'event-card' : EventCard,
  },

  data() {
    return {
      ftArticle: null,
      asArticles: [],
      news: [],
      loading: null,
    }
  },

  created() {
    this.loading = true

    feedApi.feed(true, {
      is_featured: true,
      is_pinned: false,
      type: 'Modules\\Article\\Entities\\Article',
      per_page: 3,
      page: 1
    }).then((response) => {
      if (response.data.data[0]) {
        this.ftArticle = response.data.data[0]
      }

      if (response.data.data[1]) {
        this.asArticles.push(response.data.data[1])
      }

      if (response.data.data[2]) {
        this.asArticles.push(response.data.data[2])
      }
    })

    feedApi.feed(true, {
      is_pinned: false,
      per_page: 8,
      page: 1
    }).then((response) => {
      this.news = response.data.data
    })
  },

  updated() {
    this.loading = false;
  }
}
</script>

<style lang="scss">
.home-page {
  .featured-articles {
    min-height: 466px;
  }
  .home-slider {
    display: none;
    @media (max-width: 767px) {
      display: block;
    }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 18px;
      opacity: 0.75;
      margin: 0;
    }
    .el-carousel {
      height: 250px;
      .el-carousel__container {
        height: 250px;

      }
      .article-slider-items {
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        text-align: center;
        .article-slider {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-right: -50%;
          transform: translate(-50%, -30%);
          padding: 0 45px;
          span {
            padding: 3.5px 25px;
            background-color: $primary-color;
            color: $white-color;
          }
          h2 {
            color: $white-color;
          }
        }
      }
    }
  }
  .join-hub {
    line-height: 80px;
    background-color: $white-color;
    span {
      font-weight: bold;
      color: #4A4A4A;
      font-size: 16px;
      @media (max-width: 767px) {
        margin-top: 25px;
        display: block
      }
    }

    .download-app {
      display: inline;
      svg {
        display: inline-block;
        margin-left: 15px;
      }
    }
    @media (max-width: 767px) {
      .container {
        text-align: center;
        line-height: 32px;
        .download-app {
          display: inherit;
          margin: 25px 0;
        }
      }
    }
    @media (min-width: 768px) {
      height: 80px;
      span {
        font-size: 16px;
      }
      .download-app {
        display: inline;
        float: right;
        width: 230px;
        svg {
          display: inline-block;
        }
      }
    }
    @media (min-width: 992px)  {
      span {
        font-size: 20.5px;
      }
    }
  }
  .articles {
    min-height: 300px;
    padding: 65px 0 35px 0;
    .el-row {
      &.el-row-articles {
        margin-bottom: 30px;
      }
      .el-col-newsitem {
        margin-bottom: 30px;
      }
      .btn-view-more {
        margin-top: 5px;
      }
      &:nth-of-type(1) {
        span {
          font-weight: bold;
          color: #4A4A4A;
          font-size: 18px;
          line-height: 40px;
          @media (max-width: 767px) {
            font-size: 16px;
          }
        }
        @media (max-width: 575px) {
          text-align: center;
          span {
            margin-bottom: 20px;
          }
          .el-col {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}
</style>
