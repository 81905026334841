import http from '@/services/http/app-http-driver'

const resolveURL = (url, isPublic = false) => {
  if (isPublic) {
    url = `public-${url}`
  }

  return url
}

export default {
  feed(isPublic = false, params = {}) {
    return http({ url: resolveURL('feed', isPublic), method: 'get', params })
  },
  feedCampaign(isPublic = false, id, params = {}) {
    return http({ url: resolveURL(`feed/campaign/${id}`, isPublic), method: 'get', params })
  },
  feedRelated(isPublic = false, params = {}) {
    return http({ url: resolveURL('feed/related', isPublic), method: 'get', params })
  },
  article(id, isPublic = false, params = {}) {
    return http({ url: resolveURL(`articles/${id}`, isPublic), method: 'get', params })
  },
  event(id, isPublic = false, params = {}) {
    return http({ url: resolveURL(`events/${id}`, isPublic), method: 'get', params })
  },
  reward(id, isPublic = false, params = {}) {
    return http({ url: resolveURL(`rewards/${id}`, isPublic), method: 'get', params })
  },
  campaigns: {
    index(isPublic = false, params = {}) {
      return http({ url: 'campaigns', method: 'get', params })
    },
  },
  like(data = {}) {
    return http({ url: '/like', method: 'post', data })
  },
  unlike(data = {}) {
    return http({ url: '/unlike', method: 'post', data })
  },
  reminderOptions(params = {}) {
    return http({ url: '/reminder-options', method: 'get', params })
  },
  setReminder(data = {}) {
    return http({ url: '/set-reminder', method: 'post', data })
  },
  unsetReminder(data = {}) {
    return http({ url: '/unset-reminder', method: 'post', data })
  },
  tags(params = {}) {
    return http({ url: '/tags', method: 'get', params })
  },
}
