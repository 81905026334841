<template>
  <div
    class="event-card"
    :class="{ featureCat: featured }"
  >
    <span class="text-white">{{ featured ? 'Featured' : 'Event' }}</span>
    <div class="event-date">
      <h2 class="text-white">
        {{ title }}
      </h2>
      <p
        v-if="sameDay"
        class="text-white"
      >
        {{ startDate }}
        <br />
        {{ startTime }} - {{ endTime }}
      </p>
      <p
        v-if="!sameDay"
        class="text-white"
      >
        {{ startDate }}, {{ startTime }}
        <br />
        to {{ endDate }}, {{ endTime }}
      </p>
      <p class="text-white">
        {{ venue }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    startDate: {
      type: String,
      default: '',
    },
    startTime: {
      type: String,
      default: '',
    },
    endDate: {
      type: String,
      default: '',
    },
    endTime: {
      type: String,
      default: '',
    },
    venue: {
      type: String,
      default: '',
    },
    featured: {
      type: Boolean,
      default: true,
    },
    sameDay: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.event-card {
  overflow: hidden;
  border-radius: 3px;
  height: 265px;
  background-color: $feed-event-card-bg-color;
  position: relative;
  p {
    line-height: 20px;
  }
  h2 {
    margin-top: 80px;
    margin-bottom: 35px;
    width: 95%;
    font-size: 18px;
    line-height: 28px;
    min-height: 75px;
  }
  > span {
    position: absolute;
    top: 25px;
    font-size: 12px;
    display: block;
    padding: 5px 15px;
    background-color: $feed-event-card-pill-bg-color;
    color: $feed-event-card-pill-fg-color;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .event-date {
    margin-top: 65px;
    margin-left: 16px;
    p {
      margin: 0;
      &:nth-of-type(4) {
        margin-top: 48px;
      }
      &:nth-of-type(5) {
        margin-top: 16px;
        font-size: 13px;
        width: 90%;
      }
    }
  }
  @media (max-width: 575px) {
    height: 275px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    height: 275px;
  }
}
.featureCat {
  > span {
    width: 100%;
    background-color: white;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
</style>
